body {
  font-family: "Lato", sans-serif;
}

.homeBanner {
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(250, 250, 250) 0%,
    rgb(225, 234, 238) 90%
  );
  min-height: 100vh;
}

.ourAim {
  font-family: "Abril Fatface";
  color: #455d7a9d;
  font-size: 80px;
}

.ourAimMobile {
  font-family: "Abril Fatface";
  color: #455d7a9d;
  font-size: 40px;
}

.physicalAttendance {
  height: 30vh;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to right bottom, #0000002f, #000000e3);
}

.virtualAttendance {
  min-height: 30vh;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to right bottom, #0000002f, #000000e3);
}
